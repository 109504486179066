<template>
  <div>欢迎使用杉谷科技&科立华智能科技安全AI实时分析系统！</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
